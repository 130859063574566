<template>
  <div class="publisher-statistic" :class="{'_loading' : isLoadingLists}">
    <BModal
        v-model="openedFieldsModal"
        size="lg"
        id="modal"
        ok-only
        title="Показатели"
        @hide="toggleViewFields"
    >
      <StatisticFieldsModal v-if="availableFields" :fields="availableFields" :disable-method="disableFields" :state="queryObj.select" @pick-fields="setFields" @submit="openedFieldsModal = false"/>
    </BModal>

    <div v-show="isLoadingLists" class="publisher-statistic__loader">
      <b-spinner variant="primary" label="Spinning" />
    </div>
    <b-card no-body class="publisher-statistic__filters" :class="{'_loading' : isLoadingLists}">
      <b-row class="px-2">
        <b-col md="5" lg="3">
          <b-form-group label="Выберите даты">
            <date-range-picker
                :disabled="!!isLoadingLists"
                id="datepicker"
                ref="picker"
                :singleDatePicker="isDatePickerSingle"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :maxDate="maxDate"
                autoApply
                opens="right"
                lang="ru"
                v-model="dateRange"
                @update="setDate"
                linkedCalendars
            />
          </b-form-group>
        </b-col>
        <b-col md="7" sm="6" class="pl-md-0">
          <div v-if="!isManaged" class="col-sm-6 p-0 publisher-statistic__presets">

            <DropDownFilter
                placeholder="Выберите отчет"
                is-removable
                :disabled="isLoadingLists"
                :on-change="pickPreset"
                :facets="presetsList"
                label="Отчеты"
                :multiple="false"
                :state="queryObj.platforms"
                @remove="removePresetHandler"
                track-by="id"  name="presets"/>
          </div>
          <div v-else class="publisher-statistic__preset-input-wrap">
            <div class="publisher-statistic__preset-input col-sm-6 p-0">
              <form-input form-group-class="publisher-statistic__preset-form-group" id="preset" vid="preset" v-model="presetName" placeholder="Введите название" label="Отчеты"></form-input>
              <feather-icon class="input-cancel cursor-pointer" icon="XIcon" size="16" @click="resetNewPreset" />
            </div>
            <b-button variant="primary" class="ml-2" @click="savePreset">Сохранить</b-button>
          </div>
        </b-col>
      </b-row>
      <p class="px-2 mb-0 mt-1 mt-md-0" style="padding-bottom: 8px">Фильтры</p>
      <b-row v-if="!isLoadingPreset" class="px-2 min-height-360">
        <b-col md="4" lg="3">

          <ToFilterSelect :model-value="filterToShow" @update:model-value="setFilterToShow"
                          :filters-options="filtersOptions" :state="queryObj.filters"
                          :facets="namedLists" />
        </b-col>

        <b-col md="8" lg="9" class="multiselect-col" v-show="filterToShow === 'campaigns'">
          <b-row class="d-flex">
            <MultiselectCheckboxesWithValidation
                class="col-12 mt-1 mt-md-0 mb-0 px-md-0 pr-0"
                name="campaigns"
                :model-value="queryObj.filters.campaigns"
                @update:model-value="setCampaigns($event, 'campaigns')"
                :facets="campaignsList"
                v-model="queryObj.filters.campaigns"
                :state="queryObj.filters.campaigns"
                track-by="id"
                is-paginated
                no-result-text="Не найдено подходящих РК. Попробуйте выбрать другого рекламодателя"
                :is-loading="isLoadingLists"
                :lists="namedLists"
                @pick-all="pickAllItems"
                @paginate="handlePaginateList"
                @search="handleListsSearch"/>
          </b-row>
        </b-col>

        <b-col md="8" lg="9" class="multiselect-col" v-show="filterToShow === 'advertisement_types'">
          <b-row class="d-flex">
            <MultiselectCheckboxes
                class="col-12 mt-1 mt-md-0 mb-0 px-md-0 pr-0"
                :on-change="setFilter"
                name="advertisement_types"
                :facets="advertisementTypesList"
                :state="queryObj.filters.advertisement_types"
                track-by="id"
                :is-searchable="false"
                @search="handleListsSearch"/>
          </b-row>
        </b-col>


        <b-col md="8" lg="9" class="multiselect-col" v-show="filterToShow === 'device_types'">
          <b-row class="d-flex">
            <MultiselectCheckboxes
                class="col-12 mt-1 mt-md-0 mb-0 px-md-0 pr-0"
                :on-change="setFilter"
                name="device_types"
                :facets="devicesList"
                :state="queryObj.filters.device_types"
                track-by="id"
                :is-searchable="false"
                @search="handleListsSearch"/>
          </b-row>
        </b-col>

        <b-col md="8" lg="9" class="multiselect-col" v-show="filterToShow === 'geos'">
          <b-row class="d-flex">
            <MultiselectCheckboxesWithValidation class="col-12 col-sm-6 mt-1 mt-md-0 mb-1 mb-md-0 px-md-0 pr-0"
                                                 name="geos"
                                                 :model-value="queryObj.filters.geos"
                                                 @update:model-value="pickGeo($event, 'geos')"
                                                 :facets="geoList"
                                                 :state="queryObj.filters.geos"
                                                 track-by="id"
                                                 is-paginated
                                                 :lists="namedLists"
                                                 @pick-all="pickAllItems"
                                                 @paginate="handlePaginateList"
                                                 @search="handleListsSearch"/>

            <MultiselectCheckboxesWithValidation class="col-12 col-sm-6 pr-0"
                                                 name="regions"
                                                 :model-value="queryObj.filters.regions"
                                                 @update:model-value="setFilter($event, 'regions')"
                                                 :facets="regionsList"
                                                 :state="queryObj.filters.regions"
                                                 track-by="id"
                                                 is-paginated
                                                 :lists="namedLists"
                                                 @pick-all="pickAllItems"
                                                 @paginate="handlePaginateList"
                                                 @search="handleListsSearch"/>
          </b-row>
        </b-col>

        <b-col md="8" lg="9" class="multiselect-col" v-show="filterToShow === 'oses'">
          <b-row class="d-flex">

            <MultiselectCheckboxesWithValidation class="col-12 col-sm-6 mt-1 mt-md-0 mb-1 mb-md-0 px-md-0 pr-0"
                                                 name="oses"
                                                 :model-value="queryObj.filters.oses"
                                                 @update:model-value="pickOS($event, 'oses')"
                                                 :facets="osList"
                                                 :state="queryObj.filters.oses"
                                                 track-by="id"
                                                 is-paginated
                                                 :lists="namedLists"
                                                 @pick-all="pickAllItems"
                                                 @paginate="handlePaginateList"
                                                 @search="handleListsSearch"/>

            <MultiselectCheckboxesWithValidation class="col-12 col-sm-6 pr-0"
                                                 name="os_versions"
                                                 :model-value="queryObj.filters.os_versions"
                                                 @update:model-value="setFilter($event, 'os_versions')"
                                                 :facets="osVersionsList"
                                                 :state="queryObj.filters.os_versions"
                                                 track-by="id"
                                                 is-paginated
                                                 :lists="namedLists"
                                                 @pick-all="pickAllItems"
                                                 @paginate="handlePaginateList"
                                                 @search="handleListsSearch"/>

          </b-row>
        </b-col>

        <b-col md="8" lg="9" class="multiselect-col" v-show="filterToShow === 'browsers'">
          <b-row class="d-flex">
            <MultiselectCheckboxesWithValidation class="col-12 col-sm-6 mt-1 mt-md-0 mb-1 mb-md-0 px-md-0 pr-0"
                                                 name="browsers"
                                                 :model-value="queryObj.filters.browsers"
                                                 @update:model-value="pickBrowser($event, 'browsers')"
                                                 :facets="browsersList"
                                                 :state="queryObj.filters.browsers"
                                                 track-by="id"
                                                 is-paginated
                                                 :lists="namedLists"
                                                 @pick-all="pickAllItems"
                                                 @paginate="handlePaginateList"
                                                 @search="handleListsSearch"/>

            <MultiselectCheckboxesWithValidation class="col-12 col-sm-6 pr-0"
                                                 name="browser_versions"
                                                 :model-value="queryObj.filters.browser_versions"
                                                 @update:model-value="setFilter($event, 'browser_versions')"
                                                 :facets="browsersVersionsList"
                                                 :state="queryObj.filters.browser_versions"
                                                 track-by="id"
                                                 is-paginated
                                                 :lists="namedLists"
                                                 @pick-all="pickAllItems"
                                                 @paginate="handlePaginateList"
                                                 @search="handleListsSearch"/>
          </b-row>
        </b-col>
      </b-row>
      <div v-else class="px-2 min-height-360">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>


      <b-row class="my-2 px-2 justify-content-between">
        <b-col sm="12" class="px-1">
          <b-form-group label="Группировка">
            <b-form-checkbox-group
                class="group-by d-flex flex-wrap"
                id="group"
                @change="changeGroup"
                @input="inputGroup"
                v-model="queryObj.group"
                name="group"
                stacked
            >
              <b-form-checkbox v-for="checkbox in groupOptions" :value="checkbox.value" :disabled="disableForGroup(checkbox)">{{checkbox.text}}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>

      </b-row>

      <!--      <b-row class="px-2 mx-0">-->
      <!--       -->
      <!--      </b-row>-->
      <b-row class="mb-2 px-2">

        <div class="d-flex justify-content-between align-items-center flex-wrap w-100">
          <div class="col-lg-6">
            <b-button class="col col-sm-3" :disabled="isLoadingLists || queryObj.group.length === 0" variant="primary" @click="fetchStatistic">Загрузить</b-button>
            <b-button class="col col-sm-3 ml-0 ml-md-2 mt-1 mt-md-0" @click="resetFilters">Сброс</b-button>
          </div>
          <div class="col-lg-6">
            <div class="d-lg-flex justify-content-end mt-md-1 mt-lg-0">
              <BButton class="col col-md-4 mr-0 mr-md-1 mr-lg-0 mt-1 mt-md-0" :disabled="!data" @click="handleViewChart">{{ isChartShow ? 'Скрыть график' : 'Показать график' }}</BButton>
              <BButton :disabled="!data" @click="handleExportXls" class="col col-md-3 col-lg-4 mr-md-1 mr-lg-0 ml-lg-2 mt-1 mt-md-0">Экспорт XLS</BButton>
              <BButton class="col col-sm-4 ml-0 ml-lg-2 mt-1 mt-md-0" variant="danger" @click="toggleViewFields">Выбрать показатели</BButton>
            </div>
          </div>
          <!--          <b-button class="ml-2" variant="primary" @click="savePreset">Сохранить</b-button>-->
        </div>
      </b-row>

      <transition name="fade">
        <div v-show="isChartShow" class="publisher-statistic__charts">
          <b-row class="pr-2">
            <b-col sm="12">
              <LineChart
                  :chart-data="chartsSeries"
                  :chart-options="chartOptions"
                  :chart-group="chartGroup"
              />
            </b-col>
          </b-row>
        </div>
      </transition>

      <div ref="scroll">
        <table-lazy :error="!!error" :is-loading="isLoading" :is-loading-more="isLoadingMore">
          <statistics-table :statistics="data && data.result || []" :multi-group="multiGroupDataLoaded" :fields="selectTables" :sums="data && data.sum || {}" :group="groupLabels" />
        </table-lazy>
        <p class="text-center mb-3" v-if="(!(data && data.debug) && !isLoading)">Нажмите кнопку "Загрузить" для отображения статистики и построения графиков</p>
      </div>
    </b-card>
  </div>
</template>

<script>

import MultiselectCheckboxes from "@/components/MultiselectCheckboxes"
import MultiselectCheckboxesWithValidation from "@/components/MultiselectCheckboxesWithValidation"
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BRow,
  BSpinner
} from 'bootstrap-vue'
import LineChart from "@core/components/statistics-cards/LineChart"
import StatisticsTable from "@/components/StatisticsTable"
import TableLazy from '@/components/TableLazy'
import DropDownFilter from "@/components/DropDownFilter"
import {usePage} from '@/use/page'
import {infiniteScroll} from "@/use/infiniteScroll"
import {
  fetchAdvertisementType,
  fetchDate,
  fetchDeviceList,
  fetchRegionsList,
  fetchFields, storePreset, fetchPreset, removePreset
} from "@/api/lists"
import {ref, computed, watch, reactive} from "@vue/composition-api"
import DateRangePicker from '@lexankh/vue2-daterange-picker'
import {statisticQueries} from "@/use/statisticQueries"
import {fetchAdvertiserStatistics, fetchManagerStatisticsExperimental} from "@/api/statistics"
import {objToQuery} from "@core/utils/utils"
import statisticLists from "@/use/statisticLists"
import MultiselectWithValidation from '@/components/MultiselectWithValidation'
import StatisticFieldsModal from "@/views/StatisticFieldsModal"
import ToFilterSelect from "@/components/ToFilterSelect"
import FormInput from "@/components/FormInput"

export default {
  name: 'AdvertiserStatistic',
  components: {
    FormInput,
    ToFilterSelect,
    StatisticFieldsModal,
    LineChart,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormRadioGroup,
    BFormDatepicker,
    StatisticsTable,
    TableLazy,
    DropDownFilter,
    DateRangePicker,
    MultiselectWithValidation,
    MultiselectCheckboxesWithValidation,
    MultiselectCheckboxes
  },
  data() {
    return {
      search: '',
      activeFilter: null,
      pickedFields: null,
      campaigns: [],
      openedFieldsModal: false,
    }
  },
  computed: {
    sortedFields() {
      const fields = this.data.field.filter(obj => this.pickedFields.indexOf(obj.key) !== -1)

      this.$store.dispatch('statistics/SET_FIELDS', fields)
      return fields
    }
  },
  watch: {
    data() {
      this.initialSortState = this.$store.state.statistics.sortedFields

      this.$refs.scroll.scrollIntoView({behavior: 'smooth'})
    }
  },
  setup(_, ctx) {

    const {
      isLoading,
      data,
      error,
      loadMoreData,
      isLoadingMore,
      fetchPageData
    } = usePage(fetchAdvertiserStatistics, _, ctx, 'notPreload')
    const {scroll} = infiniteScroll(loadMoreData, isLoadingMore)
    let {
      queryObj,
      fetchStatistic
    } = statisticQueries(fetchPageData, ctx)


    const isManaged = ref(false);
    const isLoadingPreset = ref(false);
    const isLoadingCampaigns = ref(false);
    const isDatePickerSingle = ref(false);
    const maxDate = ref(new Date());

    //lists

    const {
      advertisementTypesList,
      campaignsList,
      devicesList,
      geoList,
      osList,
      browsersList,
      regionsList,
      browsersVersionsList,
      geoListNextPage,
      osVersionsList,
      presetsList,
      currentDateString,
      todayDate,
      dateRange,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
      namedLists,
      setQuery,
    } = statisticLists()

    const availableFields = ref([])
    const availableFieldsArr = ref([])
    const initialFilterState = reactive({
      filters: {
        geos: [],
        publishers: [],
        placements: [],
        platforms: [],
        advertisement_types: null,
        device_types: null,
        user_id: null,
        oses: [],
        browsers: [],
        start_date: null,
        end_date: null,
        browser_versions: [],
        os_versions: [],
        dsps: [],
        regions: [],
        campaigns: [],
      },
      group: ['date'],
      select: ["sum_init","sum_load","fill_rate","vtr","sum_click","ctr"],
    })

    const selectTables = computed(() => {
      if(!availableFieldsArr.value) return []
      if(data.value) return data.value.field
      const picked = availableFieldsArr.value.filter((item) => {
        return queryObj.select.includes(item.key);
      })
      const group = groupOptions.filter(item => queryObj.group.includes(item.value))
      let label;
      group.forEach((item, index) => {
        if(index===0) {
          label = item.text;
        } else {
          label+= ' + ';
          label+= item.text
        }
      })
      return [{key: 'group', label: label} ,...picked]
    })

    const groupLabels = computed(() => {
      return groupOptions.filter(item => queryObj.group.includes(item.value)).map(i => i.text)
    })


    const filterToShow = ref('campaigns')
    const filtersOptions = [
      {
        text: 'РК',
        value: 'campaigns'
      },
      {
        text: 'Тип рекламы',
        value: 'advertisement_types'
      },
      {
        text: 'Тип устройства',
        value: 'device_types'
      },
      {
        text: 'ГЕО',
        value: 'geos'
      },
      {
        text: 'ОС',
        value: 'oses'
      },
      {
        text: 'Браузер',
        value: 'browsers'
      }
    ]

    //charts
    const fieldsIgnoreList = ['group']
    const chartPalette = ['#ee1919', '#008ffb', '#00e396', '#fe807d', '#feb019', '#ff4560', '#775d6c', '#775dd0', '#ffe70c']
    const chartOptions = {
      chart: {
        height: 350,
        type: "line",
        stacked: false
      },
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        }
      },
      stroke: {
        width: 2
      },
      colors: chartPalette,
      plotOptions: {
        bar: {
          columnWidth: "20%"
        }
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40
      },
      xaxis: {
        labels: {show: true},
        categories: [],
      },
      yaxis: []
    }

    const chartsData = ref([])
    const chartsSeries = ref([])
    const chartGroup = ref([])

    const groupOptions = [
      {
        text: 'Дата',
        value: 'date'
      },
      {
        text: 'По часам',
        value: 'hour',
      },
      {
        text: 'РК',
        value: 'campaign'
      },
      {
        text: 'ГЕО',
        value: 'geo'
      },
      {
        text: 'Регион',
        value: 'region'
      },
      {
        text: 'Девайс',
        value: 'device'
      },
      {
        text: 'ОС',
        value: 'os'
      },
      {
        text: 'Версия ОС',
        value: 'osVersion'
      },
      {
        text: 'Браузер',
        value: 'browser'
      },
      {
        text: 'Версия браузера',
        value: 'browserVersion'
      },
    ]

    const multiGroupDataLoaded = ref(false);

    const disableFields = (el) => {
      if(!(el.key === 'accumulate_freq')) return false;

      return !(queryObj.group.includes('hour') && queryObj.group.length === 1);
    }

    const disableForGroup = (el) => {
      if(queryObj.group.length === 0) return false;
      switch(el.value){
        case 'date':
          if(queryObj.group.includes('hour')) return true
          break;
        case 'hour':
          if(queryObj.group.includes('date')) return true
          break;
        default:
          if(queryObj.group.includes(el.value)) return false
          if(['publisher', 'platform', 'placement', 'dsp', 'campaign', 'geo', 'region', 'device', 'os', 'osVersion', 'browser', 'browserVersion']
              .includes(queryObj.group[1])) return true
          if(['publisher', 'platform', 'placement', 'dsp', 'campaign', 'geo', 'region', 'device', 'os', 'osVersion', 'browser', 'browserVersion']
              .includes(queryObj.group[0])) return true
          if(queryObj.group.length === 2) return true
          break;
      }
    }

    const pickersFor = (name, payload) => {
      if(payload === 'all') return;
      switch (name) {
        case 'oses':
          return pickOS(payload, name, false)
        case 'geos':
          return pickGeo(payload, name, false);
        case 'browsers':
          return pickBrowser(payload, name, false);
        case 'campaigns':
          return setCampaigns(payload, name);
        default:
          return setFilter(payload, name)
      }
    }

    const pickAllItems = (name, state) => {
      if(state) {
        setQuery(name, 'all', '1');
        handleLoadLists([name], isLoadingLists)
            .then((res) => {
              const allIds = res.map((item) => item.id)
              pickersFor(name, allIds);
            })
      } else {
        queryObj.filters[name] = []
      }
    }

    const setFilter = (value, name) => {
      if(!Array.isArray(value)) {
        queryObj.filters[name] = value;
      } else {
        queryObj.filters[name] = value.filter(item => item !== 'all');
      }
      if(!isManaged.value) isManaged.value = true;
      localStorage.setItem('advertiser-statistic', JSON.stringify(queryObj));
    }

    const setCampaigns = (value, name) => {
      setFilter(value, name);
      presetName.value = 'РК ' + campaignsList.value.filter(opt => value.includes(opt.id)).map((item) => item.name).join(', ')
    }

    const pickOS = (value, name, toLoad = true) => {
      if(toLoad) isLoadingLists.value = true
      setFilter(value, name)

      setQuery('os_versions', 'oses', queryObj.filters.oses);
      setQuery('os_versions', 'os_versions', queryObj.filters.os_versions);

      if(toLoad) handleLoadLists(['os_versions'], isLoadingLists)
    }

    const setFilterToShow = (value) => {
      filterToShow.value = value;
    }

    const pickGeo = (value, name, toLoad = true) => {
      setFilter(value, name)

      if (toLoad) {
        fetchRegionsList(objToQuery({'geo_id': [...queryObj.filters.geos]}))
            .then((res) => {
              regionsList.value = res.data.data
            })
            .finally(() => {
              isLoadingLists.value = false
            })
      }
    }

    const pickBrowser = (value, name, toLoad = true) => {
      if(toLoad) isLoadingLists.value = true
      setFilter(value, name)

      setQuery('browser_versions', 'browsers', queryObj.filters.browsers);
      setQuery('browser_versions', 'browser_versions', queryObj.filters.browser_versions);

      if(toLoad) handleLoadLists(['browser_versions'], isLoadingLists)
    }

    const isLoadingLists = ref(2)

    const allLists = ['geos',
      'oses', 'browsers', 'browser_versions', 'os_versions', 'regions', 'presets', 'campaigns'];

    if (Object.keys(ctx.root.$route.params).length) {
      const route = ctx.root.$route;

      for (const key in route.params) {
        setQuery(key, key, route.params[key]);
        handleLoadLists(allLists, isLoadingLists);
      }
    } else if (localStorage.hasOwnProperty('advertiser-statistic')) {
      const data = JSON.parse(localStorage.getItem('advertiser-statistic'))
      queryObj.filters = Object.assign({}, data.filters);
      queryObj.select = data.select;
      queryObj.group = data.group;

      dateRange.value.endDate = new Date(queryObj.filters.end_date)
      dateRange.value.startDate = new Date(queryObj.filters.start_date)

      if(queryObj.group.includes('hour')) isDatePickerSingle.value = true;

      setQuery('geos', 'geos', data.filters.geos);
      setQuery('regions', 'regions', data.filters.regions);
      pickGeo(data.filters.geos, 'geos', false);

      setQuery('oses', 'oses', data.filters.oses);
      setQuery('os_versions', 'os_versions', data.filters.os_versions);
      pickOS(data.filters.oses, 'oses', false);


      setQuery('browsers', 'browsers', data.filters.browsers);
      setQuery('browser_versions', 'browser_versions', data.filters.browser_versions);
      pickBrowser(data.filters.browsers, 'browsers', false);

      setQuery('campaigns', 'advertisers', data.filters.dsps);
      setQuery('campaigns', 'campaigns', data.filters.campaigns);


      handleLoadLists(allLists, isLoadingLists);
    } else {
      handleLoadLists(allLists,isLoadingLists)
    }

    Promise.all([
      fetchAdvertisementType(),
      fetchDeviceList(),
      fetchDate(),
      fetchFields()])
        .then(values => {
          advertisementTypesList.value = values[0].data.data
          devicesList.value = values[1].data.data
          currentDateString.value = values[2].data.current_date
          availableFields.value = values[3].data

          const availableFieldsMap = values[3].data.map((group) => {
            return group.fields;
          })
          availableFieldsArr.value = availableFieldsMap.flat()
          // set actual date
          maxDate.value = new Date(currentDateString.value);
          if(!localStorage.hasOwnProperty('advertiser-statistic') || Object.keys(ctx.root.$route.params).length) {
            todayDate.value = new Date(currentDateString.value)
            dateRange.value.endDate = todayDate.value
            const weekEarlier = todayDate.value.getDate() - 7
            dateRange.value.startDate.setDate(weekEarlier)

            queryObj.filters.start_date = dateRange.value.startDate.toLocaleDateString('en-CA')
            queryObj.filters.end_date = dateRange.value.endDate.toLocaleDateString('en-CA')
          }
          initialFilterState.filters.start_date = dateRange.value.startDate.toLocaleDateString('en-CA');
          initialFilterState.filters.end_date = dateRange.value.endDate.toLocaleDateString('en-CA')
        })


    const isChartShow = ref(false);

    const handleViewChart = () => {
      isChartShow.value = !isChartShow.value;
    }

    const handleExportXls = () => {
      ctx.emit('exportXls');
    }

    // charts make
    const labelFormatter = function (value) {
      if(!value) return 0;
      if(value === (5e-324)) {
        return value.toFixed(0);
      }
      if( value <= 100) {
        return Number.isInteger(value) ? value : value.toFixed(1);
      }
      let val = Math.abs(value)
      if (val >= 100000) {
        return (val / 1000000).toFixed(1) + ' M';
      }
      return val.toFixed(0);
    }

    const makeMultiChartsData = () => {

      chartGroup.value = data.value.result[0].data.map(obj => obj.group1)
      let y = [];
      chartOptions.yaxis = [];

      data.value.field.forEach((field, index) => {

        if (fieldsIgnoreList.includes(field.key)) return

        const fieldObj = {}

        fieldObj.key = field.key
        fieldObj.label = field.label
        fieldObj.series = []

        data.value.result.forEach((subgroup) => {
          const yAxis = subgroup.data.map(obj => obj[field.key])
          fieldObj.series.push({
            name: field.label + ' ' + subgroup.key,
            data: [...yAxis]
          })
        })

        y.push(fieldObj);

        let maxY;

        const allValues = fieldObj.series.map(serie => serie.data).flat();


        if (!field.label.includes(', %')) {
          maxY = Math.round(Math.max(...allValues) * 1.25)
        } else {
          const checkMax =  Math.ceil(Math.max(...allValues) * 1.25)
          maxY = checkMax > 100 ? 100 : checkMax;
        }

        fieldObj.series.forEach((subserie, index) => {
          if(index === 0) {
            chartOptions.yaxis.push(
                {
                  seriesName: subserie.name,
                  opposite: !!field.label.includes(', %'),
                  labels:{show: true,
                    formatter: labelFormatter
                  },
                  tickAmount: 4,
                  logarithmic: false,
                  title: {text: field.label,},
                  min: 0,
                  max: maxY,
                })
          } else {
            chartOptions.yaxis.push({
              seriesName: fieldObj.series[0].name,
              show: false,
              labels:{show: false,
                formatter: labelFormatter
              },
            })
          }
        })
      })
      let series = [];

      y.forEach((item) => {
        series.push(...item.series);
      })
      chartsSeries.value = series;
    }

    const makeChartsData = () => {
      chartGroup.value = data.value.result.map(obj => obj.group)
      let y = [];
      chartOptions.yaxis = [];

      data.value.field.forEach((field, index) => {

        if (fieldsIgnoreList.includes(field.key)) return

        const fieldObj = {}
        const yAxis = data.value.result.map(obj => obj[field.key])
        fieldObj.key = field.key
        fieldObj.label = field.label
        fieldObj.series = [{
          name: field.label,
          data: [...yAxis]
        }]
        y.push(fieldObj)

        let maxY;

        if (!field.label.includes(', %')) {
          maxY = Math.round(Math.max(...yAxis) * 1.25)
        } else {
          const checkMax =  Math.ceil(Math.max(...yAxis) * 1.25)
          maxY = checkMax > 100 ? 100 : checkMax;
        }

        chartOptions.yaxis.push(
            {
              opposite: !!field.label.includes(', %'),
              labels:{show: true,
                formatter: labelFormatter
              },
              tickAmount: 4,
              logarithmic: false,
              title: {text: field.label,},
              min: 0,
              max: maxY
            })
      })
      let series = [];
      series = y.map((serie) => {
        return {
          name: serie.label,
          data: serie.series[0].data
        }
      })
      chartsSeries.value = series;
    }


    watch(
        () => data,
        () => {

          if(data.value.field.length) {
            if(queryObj.group.length > 1) {
              multiGroupDataLoaded.value = true;
              makeMultiChartsData()
            } else {
              multiGroupDataLoaded.value = false;
              makeChartsData();
            }
          }
        },
        {deep: true}
    )

    // filter handling

    const changeGroup = (e) => {
      if(!isManaged.value) isManaged.value = true;
      if(!(e.includes('hour'))) {
        isDatePickerSingle.value = false;
        queryObj.select = queryObj.select.filter(i => i !== 'accumulate_freq');
        localStorage.setItem('advertiser-statistic', JSON.stringify(queryObj));
        return;
      }
      if(e.includes('hour') && queryObj.group.length > 1) {
        queryObj.select = queryObj.select.filter(i => i !== 'accumulate_freq');
      }
      isDatePickerSingle.value = true;

      dateRange.value = {
        endDate: maxDate.value,
        startDate: maxDate.value
      }
      queryObj.filters.start_date = maxDate.value.toLocaleDateString('en-CA')
      queryObj.filters.end_date = maxDate.value.toLocaleDateString('en-CA')
      localStorage.setItem('advertiser-statistic', JSON.stringify(queryObj));
    }

    const inputGroup = (e) => {
      if(e.length === 1) queryObj.group = e;
      queryObj.group = e.sort((a,b) => {
        if(['date', 'hour'].includes(a)) return -1
      })
    }

    const resetNewPreset = () => {
      isManaged.value = false;
    }

    const removePresetHandler = (id) => {
      removePreset(id)
          .then(() => {
            handleLoadLists(['presets']);
          })
    }


    const pickPreset = (id) => {
      isLoadingLists.value = true;
      isLoadingPreset.value = true;
      fetchPreset(id)
          .then((res) => {
            queryObj.filters = Object.assign({}, res.data.data.value.filters);
            queryObj.select = res.data.data.value.select;
            queryObj.group = res.data.data.value.group;

            dateRange.value.endDate = new Date(queryObj.filters.end_date)
            dateRange.value.startDate = new Date(queryObj.filters.start_date)

            if(queryObj.group === 'hour') isDatePickerSingle.value = true;

            const filters = res.data.data.value.filters;


            setQuery('geos', 'geos', filters.geos);
            setQuery('regions', 'regions', filters.regions);
            pickGeo(filters.geos, 'geos', false);

            setQuery('oses', 'oses', filters.oses);
            setQuery('os_versions', 'os_versions', filters.os_versions);
            pickOS(filters.oses, 'oses', false);


            setQuery('browsers', 'browsers', filters.browsers);
            setQuery('browser_versions', 'browser_versions', filters.browser_versions);
            pickBrowser(filters.browsers, 'browsers', false);

            setQuery('campaigns', 'advertisers', filters.dsps);
            setQuery('campaigns', 'campaigns', filters.campaigns);

            handleLoadLists(allLists, isLoadingLists);
            isLoadingPreset.value = false;
          })
    }



    // set date to queryObj
    const setDate = (value) => {
      queryObj.filters.start_date = value.startDate.toLocaleDateString('en-CA')
      queryObj.filters.end_date = value.endDate.toLocaleDateString('en-CA')
      localStorage.setItem('advertiser-statistic', JSON.stringify(queryObj));
    }

    const resetFilters = () => {
      ctx.emit('resetFilters')
      queryObj.filters = Object.assign({}, initialFilterState.filters);
      queryObj.group = ['date'];
      isDatePickerSingle.value = false;
      queryObj.select = [...initialFilterState.select];
      isManaged.value = false;
      localStorage.setItem('advertiser-statistic', JSON.stringify(queryObj));
      handleLoadLists(['geos',
        'oses', 'browsers', 'regions', 'presets', 'campaigns'], isLoadingLists)
    }

    const setFields = (payload) => {
      queryObj.select = payload
      if(!isManaged.value) isManaged.value = true;
      localStorage.setItem('advertiser-statistic', JSON.stringify(queryObj));
    }

    const presetName = ref('');

    const savePreset = () => {
      storePreset({name: presetName.value, value: queryObj})
          .then(() => {
            presetName.value = '';
            handleLoadLists(['presets']);
            isManaged.value = false;
          })
    }

    return {
      isLoading,
      isDatePickerSingle,
      isLoadingCampaigns,
      handleViewChart,
      isChartShow,
      multiGroupDataLoaded,
      savePreset,
      setCampaigns,
      pickAllItems,
      presetName,
      availableFieldsArr,
      chartGroup,
      pickPreset,
      changeGroup,
      setFilterToShow,
      resetNewPreset,
      data,
      isManaged,
      isLoadingPreset,
      handlePaginateList,
      removePresetHandler,
      error,
      loadMoreData,
      isLoadingMore,
      fetchPageData,
      scroll,
      handleListsSearch,
      queryObj,
      maxDate,
      isLoadingLists,
      disableForGroup,
      disableFields,
      advertisementTypesList,
      devicesList,
      geoList,
      geoListNextPage,
      regionsList,
      pickGeo,
      osList,
      selectTables,
      browsersList,
      campaignsList,
      groupOptions,
      todayDate,
      handleExportXls,
      presetsList,
      inputGroup,
      currentDateString,
      setFilter,
      dateRange,
      fetchStatistic,
      setDate,
      chartOptions,
      chartsData,
      chartsSeries,
      browsersVersionsList,
      osVersionsList,
      pickBrowser,
      pickOS,
      initialFilterState,
      resetFilters,
      filterToShow,
      filtersOptions,
      availableFields,
      setFields,
      groupLabels,
      namedLists,
      pickersFor
    }
  },

  methods: {
    resetFields() {
      this.$store.dispatch('statistics/RESET_FIELDS')
      localStorage.removeItem('stat-preset')
    },
    sortFields(value, name) {
      this.pickedFields = value
    },

    toggleViewFields() {
      this.openedFieldsModal = !this.openedFieldsModal
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@lexankh/vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style lang="scss" scoped>
.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
//
//.vue-daterange-picker {
//  min-width: 100%;
//}
.multiselect-col {
  padding-right: 1.75rem;

  @media only screen and (max-width: 576px) {
    padding-right: 1.95rem;
  }
}

.publisher-statistic {
  position: relative;

  &._loading {
    pointer-events: none;
  }



  &__presets {

    & .r-filter-cell__label {
      margin-bottom: 0.5rem;
    }

    & .multiselect__container {
      max-height: 140px;
    }
  }

  &__preset-input {
    position: relative;

    & .input-cancel {
      position: absolute;
      right: 8px;
      top: 47%;
    }
  }

  &__preset-input-wrap {
    display: flex;
    align-items: center;

    & label {
      padding-bottom: 0.1rem;
    }

    & button {
      margin-top: 10px;
    }
  }

  & .w-260 {
    width: 260px;
  }

  & .min-height-360 {
    min-height: 360px;
  }

  & .group-by {
    gap: 12px;
  }

  &__loader {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 45%;
    width: 100%;
    height: 100%;
  }

  &__filters {
    padding-top: 20px;

    &._loading {
      filter: blur(4px)
    }
  }
}
</style>

<style lang="scss">

.ss-scroll {
  width: 12px;
}

.apexcharts-zoomin-icon, .apexcharts-zoomout-icon, .apexcharts-zoom-icon, .apexcharts-pan-icon, .apexcharts-reset-icon {
  display: none;
}

.publisher-statistic {
  position: relative;

  &__presets {

    & .r-filter-cell__label {
      margin-bottom: 0.5rem;
    }
  }

  &__preset-input-wrap {
    display: flex;
    align-items: center;

    & label {
      padding-bottom: 0.1rem;
    }

    & button {
      margin-top: 10px;
    }
  }

  &__preset-input {

    & .form-group > div {
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 0.357rem;

      & .form-control {
        padding: 0.438rem 2rem 0.438rem 1rem;
      }
    }
  }
}

.d-flex-bypass multiselect-col {
  display: flex;
}

.reportrange-text.form-control {
  padding: 8px 40px 0 8px;
  border: 1px solid #e8e8e8;
}

.custom-control {
  cursor: pointer;
}
</style>

